// config.ts
import { Link } from "@amzn/awsui-components-react";
import { HealthStatus, SearchHistoryInterface } from "../types";
import HealthStatusIndicator from "../components/HealthStatusIndicator";

export const compareDates = (date1: string, date2: string): number => {
  const parseDate = (dateStr: string): Date => {
    const [datePart, timePart] = dateStr.split(", ");
    const [day, month, year] = datePart.split("/").map(Number);
    const [hours, minutes, seconds] = timePart.split(":").map(Number);
    return new Date(year, month - 1, day, hours, minutes, seconds);
  };

  const d1 = parseDate(date1);
  const d2 = parseDate(date2);

  return d1.getTime() - d2.getTime();
};

export const COLUMN_DEFINITIONS = [
  {
    id: "orderId",
    header: "Transaction Id",
    width: 550,
    cell: (item: SearchHistoryInterface) => (
      <Link
        href={`?stage=${encodeURIComponent(
          item.stage
        )}&transactionId=${encodeURIComponent(item.orderId)}`}
      >
        {item.orderId}
      </Link>
    ),
    sortingField: "orderId",
  },
  {
    id: "stage",
    header: "Stage",
    width: 100,
    cell: (item: SearchHistoryInterface) => item.stage || "-",
    sortingField: "stage",
  },
  {
    id: "countryCode",
    header: "Country",
    cell: (item: SearchHistoryInterface) => item.countryCode || "-",
    sortingField: "countryCode",
  },
  {
    id: "healthStatus",
    header: "Status",
    cell: (item: SearchHistoryInterface) => (
      <HealthStatusIndicator
        healthStatus={(item.status || "unknown") as HealthStatus}
      />
    ),
    sortingField: "status",
  },
  {
    id: "date",
    header: "Last Accessed",
    cell: (item: SearchHistoryInterface) => item.date || "-",
    sortingField: "date",
    sortingComparator: (a, b) => compareDates(a.date, b.date),
  },
];
