import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Header,
  Link,
  Modal,
  SpaceBetween,
  StatusIndicator,
} from "@amzn/awsui-components-react";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import { useDeferralId } from "../contexes/DeferralIdContext";
import {
  createFlashUILink,
  createRaceLink,
  getDeferralIds,
} from "./OrderIdBar/ExternalLinks";
import { CanonicalBusinessEvent, ErrorDetails } from "../types";
import { StageContext } from "../contexes/OrderIdContext";
import ErrorContentRenderer from "./ErrorContentRenderer";
import { parseErrorDetails } from "../helpers/errorUtils";
import ModalFooter from "./ModalFooter";

interface PublishStatusProps {
  cbe: CanonicalBusinessEvent;
}

export default function PublishStatusComponent({ cbe }: PublishStatusProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const stage = useContext(StageContext);
  const { marketplaceId } = useMarketplaceId();
  const { setDeferralIds } = useDeferralId();
  const [expandAll, setExpandAll] = useState(true);

  const handleModalOpen = useCallback(() => setIsModalVisible(true), []);

  const handleExpandAll = useCallback(() => {
    setExpandAll((prev) => !prev);
  }, []);

  useEffect(() => {
    const { revenueDeferralId, royaltyDeferralId } = getDeferralIds(cbe);
    setDeferralIds((prevIds) => {
      if (
        (revenueDeferralId &&
          revenueDeferralId !== prevIds.revenueDeferralId) ||
        (royaltyDeferralId && royaltyDeferralId !== prevIds.royaltyDeferralId)
      ) {
        return {
          revenueDeferralId: revenueDeferralId || prevIds.revenueDeferralId,
          royaltyDeferralId: royaltyDeferralId || prevIds.royaltyDeferralId,
        };
      }
      return prevIds;
    });
  }, [cbe, setDeferralIds]);

  const getCbeLinks = useCallback(() => {
    const cbeLinks: { label: string; href: string }[] = [];
    if (cbe.destination === "FLARE") {
      cbeLinks.push({
        label: "Flash UI",
        href: createFlashUILink(marketplaceId, cbe.idempotenceId, stage),
      });
    } else if (cbe.destination === "RACE") {
      const { revenueDeferralId, royaltyDeferralId } = getDeferralIds(cbe);
      if (
        revenueDeferralId &&
        royaltyDeferralId &&
        revenueDeferralId !== royaltyDeferralId
      ) {
        cbeLinks.push({
          label: "Revenue Deferral",
          href: createRaceLink(marketplaceId, revenueDeferralId, stage),
        });
        cbeLinks.push({
          label: "Royalty Deferral",
          href: createRaceLink(marketplaceId, royaltyDeferralId, stage),
        });
      } else if (revenueDeferralId || royaltyDeferralId) {
        cbeLinks.push({
          label: "Deferral",
          href: createRaceLink(
            marketplaceId,
            revenueDeferralId || royaltyDeferralId || "",
            stage
          ),
        });
      } else {
        cbeLinks.push({
          label: "Order ID",
          href: createRaceLink(marketplaceId, cbe.orderId, stage),
        });
      }
    } else {
      cbeLinks.push({ label: "Unknown", href: "#" });
    }
    return cbeLinks;
  }, [cbe, marketplaceId, stage]);

  function renderSuccessLinks() {
    const cbeLinks = getCbeLinks();
    if (cbeLinks.length === 1) {
      return (
        <Link
          key="success-link"
          external
          externalIconAriaLabel="Opens in a new tab"
          href={cbeLinks[0].href}
          data-testid="FlashLink"
        >
          <StatusIndicator type="success">Publish Success</StatusIndicator>
        </Link>
      );
    }
    return (
      <SpaceBetween direction="horizontal" size="xs">
        <StatusIndicator type="success">Publish Success</StatusIndicator>
        {cbeLinks.map((link, index) => (
          <Link
            key={`success-link-${index}`}
            external
            externalIconAriaLabel="Opens in a new tab"
            href={link.href}
            data-testid={`FlashLink-${index}`}
          >
            {link.label}
          </Link>
        ))}
      </SpaceBetween>
    );
  }

  if (cbe.idempotenceId.includes("NONE")) {
    return <StatusIndicator type="info">NoOp</StatusIndicator>;
  }

  if (cbe.publishStatus === null || cbe.publishStatus === undefined) {
    return (
      <StatusIndicator type="pending">
        Status Unknown/Pending Publish
      </StatusIndicator>
    );
  }

  if (cbe.publishStatus.successFlag === true) {
    return renderSuccessLinks();
  }

  const errorDetails: ErrorDetails = parseErrorDetails(
    cbe.publishStatus.stackTrace || "No error details",
    cbe.destination
  );

  return (
    <>
      <Button
        onClick={handleModalOpen}
        variant="inline-link"
        className="publish-status-button"
      >
        <StatusIndicator type="error">Error Publishing</StatusIndicator>
      </Button>
      <Modal
        className="error-modal"
        onDismiss={() => setIsModalVisible(false)}
        visible={isModalVisible}
        closeAriaLabel="Close modal"
        size="max"
        footer={
          <ModalFooter
            stackTrace={errorDetails.stackTrace}
            inputRequestUrl={errorDetails.inputRequestUrl}
            onClose={() => setIsModalVisible(false)}
            onExpandToggle={handleExpandAll}
            expandAll={expandAll}
          />
        }
        header={
          <Header variant="h2">
            Error Type: {errorDetails.errorType || "Unknown"}
          </Header>
        }
      >
        <ErrorContentRenderer
          errorDetails={errorDetails}
          isModalVisible={isModalVisible}
          expandAll={expandAll}
        />
      </Modal>
    </>
  );
}
