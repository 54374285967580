import React, { createContext, useContext, useState } from "react";
import { HealthState } from "../types";

const initialState: HealthState = {
  overallHealth: "unknown",
  latestVersion: 0,
  eventsReceived: false,
  cbes: {
    overall: {
      produced: false,
      published: {
        success: false,
        failedIds: [],
      },
      health: "pending",
    },
    perVersion: {},
  },
};

export const HealthStateContext = createContext<HealthState | undefined>(
  undefined
);
HealthStateContext.displayName = "Health Context"; // For dev tools
const HealthStateUpdateContext = createContext<
  React.Dispatch<React.SetStateAction<HealthState>> | undefined
>(undefined);

export const HealthStateProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<HealthState>(initialState);

  return (
    <HealthStateContext.Provider value={state}>
      <HealthStateUpdateContext.Provider value={setState}>
        {children}
      </HealthStateUpdateContext.Provider>
    </HealthStateContext.Provider>
  );
};

export const useHealthState = () => {
  const context = useContext(HealthStateContext);
  if (!context) {
    throw new Error("useHealthState must be used within a HealthStateProvider");
  }
  return context;
};

export const useHealthStateUpdate = () => {
  const context = useContext(HealthStateUpdateContext);
  if (!context) {
    throw new Error(
      "useHealthStateUpdate must be used within a HealthStateProvider"
    );
  }
  return context;
};
