import { Tabs } from "@amzn/awsui-components-react";
import React from "react";
import { CanonicalBusinessEvent } from "../types";
import CbeResults from "./CbeResults";
import { createVersionNumberArray } from "../helpers/healthUtils";
import HealthStatusDisplay from "./HealthStatusDisplay";
import { useHealthState } from "../contexes/HealthStateContext";

interface Props {
  cbeData: CanonicalBusinessEvent[];
}

export default function CbeVersionSelector(props: Props) {
  const { cbeData } = props;
  const state = useHealthState();

  return (
    <Tabs
      tabs={createVersionNumberArray(cbeData).map((version) => ({
        id: `version-${version}`,
        label: (
          <HealthStatusDisplay
            name="Version"
            events={state.cbes.perVersion[version]?.cbes}
            value={version.toString()}
          />
        ),
        content: (
          <CbeResults
            cbeData={cbeData.filter((cbe) => cbe.lockVersion === version)}
          />
        ),
      }))}
    />
  );
}
