import React from "react";
import { Box, Button, SpaceBetween, CopyToClipboard } from "@amzn/awsui-components-react";

const ModalFooter = ({ stackTrace, inputRequestUrl, onClose, onExpandToggle, expandAll }) => (
  <Box float="right">
    <SpaceBetween direction="horizontal" size="xs">
      <Button onClick={onExpandToggle} variant="normal">
        {expandAll ? "Collapse All" : "Expand All"}
      </Button>
      <CopyToClipboard
        copyButtonText="Copy Raw Error"
        copyErrorText="Raw error failed to copy"
        copySuccessText="Raw error copied"
        textToCopy={stackTrace || "No error details"}
      />
      {inputRequestUrl && (
        <Button
          onClick={() => window.open(inputRequestUrl, "_blank")}
          iconName="external"
        >
          Open Request URL
        </Button>
      )}
      <Button onClick={onClose} variant="primary">
        Close
      </Button>
    </SpaceBetween>
  </Box>
);

export default ModalFooter;
