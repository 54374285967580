import React, { useState, useEffect, useRef } from "react";
import { useCollection } from "@amzn/awsui-collection-hooks";
import {
  Box,
  Button,
  Header,
  Pagination,
  Table,
  TextFilter,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import { SearchHistoryInterface } from "../types";
import { COLUMN_DEFINITIONS, compareDates } from "../config/SearchTableConfig";

export default function SearchHistory() {
  const [allItems, setAllItems] = useState<SearchHistoryInterface[]>([]);
  const textFilterRef = useRef(null);

  useEffect(() => {
    const storedOrderIds = JSON.parse(
      localStorage.getItem("orderIds") || "[]"
    ) as SearchHistoryInterface[];
    setAllItems(storedOrderIds);
  }, []);

  const { items, actions, collectionProps, filterProps, paginationProps } =
    useCollection(allItems, {
      filtering: {
        empty: (
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No resources to display.
            </Box>
          </Box>
        ),
        noMatch: (
          <Box textAlign="center" color="inherit">
            <b>No matches</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              We can't find a match.
            </Box>
            <Button onClick={() => actions.setFiltering("")}>
              Clear filter
            </Button>
          </Box>
        ),
        filteringFunction: (item, filteringText) => {
          const filterLower = filteringText.toLowerCase();
          const derivedStatus = item.status || "unknown";
          return (
            item.orderId?.toLowerCase().includes(filterLower) ||
            item.countryCode?.toLowerCase().includes(filterLower) ||
            item.stage?.toLowerCase().includes(filterLower) ||
            derivedStatus.toLowerCase().includes(filterLower)
          );
        },
      },
      pagination: { pageSize: 15 },
      sorting: {
        defaultState: {
          sortingColumn: {
            sortingField: "date",
            sortingComparator: (
              a: SearchHistoryInterface,
              b: SearchHistoryInterface
            ) => compareDates(a.date, b.date),
          },
          isDescending: true,
        },
      },
      selection: {},
    });

  const deleteSelectedItems = () => {
    const selectedItems = collectionProps.selectedItems || [];
    const updatedItems = allItems.filter(
      (item) =>
        !selectedItems.some(
          (selected) =>
            selected.orderId === item.orderId && selected.stage === item.stage
        )
    );
    setAllItems(updatedItems);
    localStorage.setItem("orderIds", JSON.stringify(updatedItems));
  };

  return (
    <Table
      {...collectionProps}
      resizableColumns
      columnDefinitions={COLUMN_DEFINITIONS}
      items={items}
      loadingText="Loading resources"
      selectionType="multi"
      header={
        <Header
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                onClick={deleteSelectedItems}
                disabled={collectionProps.selectedItems?.length === 0}
              >
                Delete selected
              </Button>
            </SpaceBetween>
          }
        >
          Recent Searches
        </Header>
      }
      filter={
        <TextFilter
          {...filterProps}
          ref={textFilterRef}
          filteringPlaceholder="Filter by Order ID, Country, Stage, or Status"
        />
      }
      pagination={<Pagination {...paginationProps} />}
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
        </Box>
      }
    />
  );
}
