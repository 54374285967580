import React, { lazy, Suspense, useContext, useMemo } from "react";
import {
  Badge,
  ContentLayout,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import CodeView from "@amzn/awsui-code-view/code-view";
import ExpandableSectionWithCopy from "./ExpandableSectionWithCopy";
import { ErrorDetails, JsonViewProps } from "../types";
import { BASE_16_CUSTOM_DARK_THEME } from "../constants";
import DarkModeContext from "../contexes/DarkModeContext";

const ReactJson = lazy(() => import("react-json-view"));

type JsonValue = string | number | boolean | null | JsonObject | JsonArray;
interface JsonObject {
  [key: string]: JsonValue;
}
type JsonArray = Array<JsonValue>;

interface ErrorContentRendererProps {
  errorDetails: ErrorDetails;
  isModalVisible: boolean;
  expandAll: boolean;
}

const ERROR_TYPES_TO_EXCLUDE = ["schema_definition_processing_error"];

const BADGE_COLORS = [
  "blue",
  "grey",
  "green",
  "red",
  "severity-critical",
  "severity-high",
  "severity-medium",
  "severity-low",
  "severity-neutral",
] as const;

type BadgeColor = (typeof BADGE_COLORS)[number];

function getColorForErrorType(errorType: string): BadgeColor {
  const index =
    errorType.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) %
    BADGE_COLORS.length;
  return BADGE_COLORS[index];
}

const ErrorContentRenderer: React.FC<ErrorContentRendererProps> = ({
  errorDetails,
  isModalVisible,
  expandAll,
}) => {
  const darkMode = useContext(DarkModeContext);

  const jsonViewProps: JsonViewProps = {
    displayDataTypes: false,
    displayObjectSize: false,
    quotesOnKeys: false,
    displayArrayKey: false,
    theme: darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default",
  } as const;

  const errorSummary = useMemo(() => {
    if (!isModalVisible) return null;

    if (
      !errorDetails?.parsedErrorDetails ||
      !Array.isArray(errorDetails.parsedErrorDetails)
    ) {
      return null;
    }

    const errorCounts: { [key: string]: number } = {};
    errorDetails.parsedErrorDetails.forEach((error) => {
      if (error && typeof error === "object" && "error_type" in error) {
        const errorType = error.error_type as string;
        if (!ERROR_TYPES_TO_EXCLUDE.includes(errorType)) {
          errorCounts[errorType] = (errorCounts[errorType] || 0) + 1;
        }
      }
    });

    return Object.entries(errorCounts).map(([errorType, count]) => {
      const badgeColor = getColorForErrorType(errorType);
      return (
        <SpaceBetween direction="horizontal" size="xxs" key={errorType}>
          <span>{count}x</span>
          <Badge color={badgeColor}>{errorType}</Badge>
        </SpaceBetween>
      );
    });
  }, [isModalVisible, errorDetails?.parsedErrorDetails]);

  if (!isModalVisible) {
    return null;
  }

  return (
    <Suspense fallback={<div>Loading content...</div>}>
      <ContentLayout>
        <SpaceBetween direction="vertical" size="l">
          {errorSummary && (
            <SpaceBetween direction="horizontal" size="xs">
              {errorSummary}
            </SpaceBetween>
          )}
          <ExpandableSectionWithCopy
            title="Error Details"
            copyText={errorDetails.displayMessage}
            expanded={expandAll}
          >
            <CodeView content={errorDetails.displayMessage} />
          </ExpandableSectionWithCopy>
          {errorDetails.parsedErrorDetails && (
            <ExpandableSectionWithCopy
              title="Parsed Error Details"
              copyText={JSON.stringify(
                errorDetails.parsedErrorDetails,
                null,
                2
              )}
              expanded={expandAll}
            >
              <ReactJson
                src={errorDetails.parsedErrorDetails}
                {...jsonViewProps}
              />
            </ExpandableSectionWithCopy>
          )}
          {errorDetails.errorRecords && (
            <ExpandableSectionWithCopy
              title="Error Records"
              copyText={JSON.stringify(errorDetails.errorRecords, null, 2)}
              expanded={expandAll}
            >
              <ReactJson src={errorDetails.errorRecords} {...jsonViewProps} />
            </ExpandableSectionWithCopy>
          )}
        </SpaceBetween>
      </ContentLayout>
    </Suspense>
  );
};

export default ErrorContentRenderer;
