import { ColumnLayout, Container, Header } from "@amzn/awsui-components-react";
import { useContext } from "react";
import useFetchEvent from "../../hooks/useFetchEvent";
import ExternalLinks from "./ExternalLinks";
import { OrderIdContext, StageContext } from "../../contexes/OrderIdContext";

export default function OrderIdBar() {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);

  const { data: eventData } = useFetchEvent(stage, orderId);

  const renderExternalLinks = () => {
    if (eventData && eventData.length > 0) {
      const lastItem = eventData[eventData.length - 1];
      if ("documentType" in lastItem) {
        const dbd = lastItem;
        return <ExternalLinks digitalBusinessDocument={dbd} />;
      }
      const aeg = lastItem;
      return <ExternalLinks accountingEventsGroup={aeg} />;
    }
    return null;
  };

  return (
    <Container>
      <ColumnLayout columns={2}>
        <Header variant="h1" description="Transaction ID">
          {orderId}{" "}
        </Header>
        {renderExternalLinks()}
      </ColumnLayout>
    </Container>
  );
}
