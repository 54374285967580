import {
  Box,
  ColumnLayout,
  Container,
  Header,
} from "@amzn/awsui-components-react";
import React, { useContext, useMemo } from "react";
import {
  AccountingEventsGroup,
  DigitalBusinessDocument,
  DigitalCostDocument,
} from "../types";
import { StageContext } from "../contexes/OrderIdContext";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import { getCountryCodeFromMarketplace } from "./OrderIdBar/ExternalLinks";
import OverallHealthStatus from "./OverallHealthStatus";

export interface Props {
  eventData: (
    | AccountingEventsGroup
    | DigitalBusinessDocument
    | DigitalCostDocument
  )[];
}

const GL_MAPPING: Record<string, string> = {
  "318": "TVOD",
  "613": "Channels",
  "628": "SVOD",
  "796": "SWM",
};

const isAccountingEventsGroup = (
  data: AccountingEventsGroup | DigitalBusinessDocument | DigitalCostDocument
): data is AccountingEventsGroup => "accountingEvents" in data;

const isDigitalCostDocument = (
  data: AccountingEventsGroup | DigitalBusinessDocument | DigitalCostDocument
): data is DigitalCostDocument =>
  "documentType" in data && data.documentType === "DigitalCostDocument";

const isDigitalBusinessDocument = (
  data: AccountingEventsGroup | DigitalBusinessDocument | DigitalCostDocument
): data is DigitalBusinessDocument =>
  "documentType" in data && data.documentType !== "DigitalCostDocument";

interface SummaryItemProps {
  label: string;
  value: React.ReactNode;
}

function SummaryItem({ label, value }: SummaryItemProps) {
  return (
    <div>
      <Box variant="awsui-key-label">{label}</Box>
      <div>{value}</div>
    </div>
  );
}
export default function Summary({ eventData }: Props) {
  const stage = useContext(StageContext);
  const { marketplaceId } = useMarketplaceId(); //
  const countryCode = getCountryCodeFromMarketplace(marketplaceId);

  const { orderDate, glProductGroup, consumptionType, fulfillmentType } =
    useMemo(() => {
      if (eventData.length === 0) {
        return {
          orderDate: undefined,
          glProductGroup: undefined,
          consumptionType: undefined,
          fulfillmentType: undefined,
        };
      }

      const lastEvent = eventData[eventData.length - 1];

      if (isAccountingEventsGroup(lastEvent)) {
        const revenueEvent = Object.values(lastEvent.accountingEvents).find(
          (event) => event.type === "REVENUE"
        );
        const fallbackEvent = Object.values(lastEvent.accountingEvents).find(
          (event) => event.type === "ROYALTY"
        );
        const selectedEvent = revenueEvent || fallbackEvent;

        if (selectedEvent) {
          return {
            orderDate: selectedEvent.orderDate,
            glProductGroup: selectedEvent.glProductGroup,
            consumptionType: revenueEvent
              ? revenueEvent.consumptionType
              : undefined,
          };
        }
        return {
          orderDate: undefined,
          glProductGroup: undefined,
          consumptionType: undefined,
        };
      }

      if (isDigitalCostDocument(lastEvent)) {
        return {
          orderDate: lastEvent.body.header.transactionDate,
          glProductGroup: lastEvent.body.body.glProductLineId,
          consumptionType: lastEvent.body.body.cost.transactionType,
        };
      }

      if (isDigitalBusinessDocument(lastEvent)) {
        const firstProduct = Object.values(lastEvent.body.body.products)[0];
        return {
          orderDate: lastEvent.body.header.transactionDate,
          glProductGroup: firstProduct?.details.glProductLineId,
          consumptionType: firstProduct?.details.activity,
          fulfillmentType:
            lastEvent.body.metadata.internalAttributes?.FULFILLMENT_TYPE,
        };
      }

      return {
        orderDate: undefined,
        glProductGroup: undefined,
        consumptionType: undefined,
      };
    }, [eventData]);

  const getGlMapping = (gl: number | string) => {
    return GL_MAPPING[gl.toString()] ?? gl;
  };
  return (
    <Container header={<Header variant="h2">Summary</Header>}>
      <ColumnLayout columns={5} borders="vertical">
        <SummaryItem label="Stage" value={stage} />
        <SummaryItem
          label="GL Product Group"
          value={glProductGroup ? getGlMapping(glProductGroup) : "-"}
        />
        <SummaryItem label="Country Code" value={countryCode} />
        <SummaryItem
          label="Order Date"
          value={orderDate ? new Date(orderDate).toUTCString() : "-"}
        />
        {consumptionType && (
          <SummaryItem label="Consumption Type" value={consumptionType} />
        )}
        {fulfillmentType && (
          <SummaryItem label="Fulfillment Type" value={fulfillmentType} />
        )}
        <SummaryItem label="Status" value={OverallHealthStatus()} />
      </ColumnLayout>
    </Container>
  );
}
