import { CanonicalBusinessEvent, HealthStatus } from "../types";

export function filterNonNoOpCBEs(
  cbes: CanonicalBusinessEvent[]
): CanonicalBusinessEvent[] {
  return cbes.filter((cbe) => !cbe.idempotenceId.includes("NONE"));
}

export function groupCBEsByVersion(cbes: CanonicalBusinessEvent[]) {
  return cbes.reduce((acc, cbe) => {
    const version = cbe.lockVersion;
    acc[version] = acc[version] || [];
    acc[version].push(cbe);
    return acc;
  }, {} as Record<number, CanonicalBusinessEvent[]>);
}
export const createVersionNumberArray = (
  cbeData: CanonicalBusinessEvent[]
): number[] =>
  Array.from(new Set(cbeData.map((cbe) => cbe.lockVersion))).sort(
    (a, b) => b - a
  );

export function determineVersionHealth(
  cbes: CanonicalBusinessEvent[]
): HealthStatus {
  if (!cbes || cbes.length === 0) return "unhealthy"; // No CBEs exist

  const meaningfulCBEs = filterNonNoOpCBEs(cbes);

  if (meaningfulCBEs.length === 0) return "pending"; // All CBEs are NoOps

  const allPublished = meaningfulCBEs.every(
    (cbe) => cbe.publishStatus?.successFlag === true
  );
  const anyFailed = meaningfulCBEs.some(
    (cbe) => cbe.publishStatus?.successFlag === false
  );
  const anyUnpublished = meaningfulCBEs.some(
    (cbe) => cbe.publishStatus === undefined
  );

  if (anyFailed) return "unhealthy";
  if (anyUnpublished) return "pending";
  if (allPublished) return "healthy";

  return "unknown";
}

export function determineOverallHealth(
  eventsReceived: boolean,
  latestCBEs: CanonicalBusinessEvent[]
): HealthStatus {
  if (!eventsReceived) {
    return "unhealthy";
  }

  return determineVersionHealth(latestCBEs);
}
