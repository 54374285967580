import {Badge, Box, CopyToClipboard, ExpandableSection, SpaceBetween, TextContent,} from "@amzn/awsui-components-react";
import ReactJson from "react-json-view";
import {useContext} from "react";
import {AccountingEventsGroup, DigitalBusinessDocument} from "../types";
import DarkModeContext from "../contexes/DarkModeContext";
import {BASE_16_CUSTOM_DARK_THEME} from "../constants";

interface Props {
  eventData: AccountingEventsGroup | DigitalBusinessDocument;
}

export default function EventResults(props: Props) {
  const {eventData} = props;
  const darkMode = useContext(DarkModeContext);

  const isAccountingEventsGroup = "accountingEvents" in eventData;

  if (isAccountingEventsGroup) {
    const {accountingEvents} = eventData;
    return (
      <SpaceBetween size="l">
        {accountingEvents &&
          Object.keys(accountingEvents).map((key) => {
            return (
              <SpaceBetween size="xl" key={key}>
                <ExpandableSection
                  header={
                    <Box variant="h4" key={key} margin={{top: "xxs"}}>
                      {key}
                      <br/>
                      {accountingEvents[key].creationDate && (
                        <TextContent>
                          <small>
                            Created on:{" "}
                            {new Date(accountingEvents[key].creationDate)
                              .toISOString()
                              .replace("T", " ")}
                          </small>
                        </TextContent>
                      )}
                    </Box>
                  }
                  variant="container"
                >
                  <ReactJson
                    src={accountingEvents[key]}
                    displayDataTypes={false}
                    sortKeys
                    theme={darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default"}
                  />
                </ExpandableSection>
              </SpaceBetween>
            );
          })}
      </SpaceBetween>
    );
  }
  // Handle DigitalBusinessDocument
  const dbd: DigitalBusinessDocument = eventData;
  const isManualRedrive =
    dbd.body?.metadata?.internalAttributes?.MANUAL_REDRIVE === true;

  return (
    <SpaceBetween size="l">
      <ExpandableSection
        header={
          <Box variant="h4" margin={{top: "xxs"}}>
            {dbd.id} (Digital Business Document){" "}
            {dbd.raw_payload && (
              <CopyToClipboard
                textToCopy={JSON.stringify(dbd.raw_payload, null, 2)}
                variant="icon"
                copyButtonText="Copy raw JSON"
                copySuccessText="Raw Dynamo JSON copied to clipboard"
                copyErrorText="Error copying JSON to clipboard"
              />
            )}
            <br/>
            <TextContent>
              <SpaceBetween size="xs" direction="horizontal">
                {isManualRedrive && <Badge color="grey">Manual Redrive</Badge>}
                <small>
                  Created on:{" "}
                  {dbd.creationDate
                    ? new Date(dbd.creationDate).toISOString().replace("T", " ")
                    : "Unknown"}{" "}
                </small>
              </SpaceBetween>
            </TextContent>
          </Box>
        }
        variant="container"
      >
        <ReactJson
          src={dbd.body}
          displayDataTypes={false}
          sortKeys
          collapsed={3}
          theme={darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default"}
        />
      </ExpandableSection>
    </SpaceBetween>
  );
}
