import { useQuery } from "@tanstack/react-query";
import { CbesApiFactory } from "../lusca-search-api/LuscaSearchApiFactory";
import { CanonicalBusinessEvent, HealthStatus } from "../types";
import { useHealthStateUpdate } from "../contexes/HealthStateContext";
import {
  groupCBEsByVersion,
  determineVersionHealth,
  determineOverallHealth,
  filterNonNoOpCBEs,
  createVersionNumberArray,
} from "../helpers/healthUtils";

export default function useFetchCbe(stage: string, postId: string) {
  const CbesApi = CbesApiFactory();
  const setHealthState = useHealthStateUpdate();

  const processCbeHealthState = (cbeData: CanonicalBusinessEvent[]) => {
    const groupedCBEs = groupCBEsByVersion(cbeData);
    const sortedVersions = createVersionNumberArray(cbeData);
    const latestVersion = sortedVersions[0];
    const latestCBEs = groupedCBEs[latestVersion] || [];
    const overallHealth = determineOverallHealth(true, latestCBEs);

    // Attach health to each version
    const perVersion = Object.entries(groupedCBEs).reduce(
      (acc, [version, cbes]) => {
        acc[+version] = {
          health: determineVersionHealth(cbes),
          cbes,
        };
        return acc;
      },
      {} as Record<
        number,
        { health: HealthStatus; cbes: CanonicalBusinessEvent[] }
      >
    );

    setHealthState((prev) => ({
      ...prev,
      latestVersion,
      overallHealth,
      eventsReceived: true,
      cbes: {
        overall: {
          produced: filterNonNoOpCBEs(latestCBEs).length > 0,
          published: {
            success: determineVersionHealth(latestCBEs) === "healthy",
            failedIds: latestCBEs
              .filter((cbe) => cbe.publishStatus?.successFlag === false)
              .map((cbe) => cbe.idempotenceId),
          },
          health: overallHealth,
        },
        perVersion,
      },
    }));
  };

  const getCbe = async () => {
    const { data } = await CbesApi.getCbes(stage, postId);
    const cbeData = data.map((item) => item as CanonicalBusinessEvent);
    processCbeHealthState(cbeData);
    return cbeData;
  };

  return useQuery(["post1 cbe", postId], () => getCbe(), {
    refetchOnWindowFocus: false,
    retry: false, // Disable retries
    enabled: false, // disable this query from automatically running
  });
}
