import { Steps } from "@amzn/awsui-components-react";
import React, { useEffect } from "react";
import { StepsProps } from "@amzn/awsui-components-react/polaris/steps/interfaces";
import {
  useHealthState,
  useHealthStateUpdate,
} from "../contexes/HealthStateContext";
import HealthStatusIndicator from "./HealthStatusIndicator";

function OverallHealthStatus() {
  const state = useHealthState();
  const setState = useHealthStateUpdate();

  const { eventsReceived, cbes } = state;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      overallHealth: cbes.overall.health,
    }));
  }, [cbes.overall.health, setState]);

  const getCBEsPublishedStatus = (): StepsProps.Status => {
    if (cbes.overall.published.success) {
      return "success";
    }

    if (
      cbes.overall.health === "pending"
    ) {
      return "in-progress";
    }

    return "error";
  };

  const steps: StepsProps.Step[] = [
    {
      status: eventsReceived ? "success" : "error",
      header: "Events Received",
    },
    {
      status: cbes.overall.produced ? "success" : "error",
      header: "CBEs Produced",
    },
    {
      status: getCBEsPublishedStatus(),
      header: "CBEs Published",
    },
  ];

  return (
    <HealthStatusIndicator
      healthStatus={cbes.overall.health}
      popoverContent={<Steps steps={steps} />}
    />
  );
}

export default OverallHealthStatus;
