import React, { useContext } from "react";

import {
  Box,
  Container,
  ExpandableSection,
  Popover,
  StatusIndicator,
  TextContent,
  Link,
} from "@amzn/awsui-components-react";

import ReactJson from "react-json-view";
import { CanonicalBusinessEvent } from "../types";
import DarkModeContext from "../contexes/DarkModeContext";
import { BASE_16_CUSTOM_DARK_THEME } from "../constants";
import PublishStatusComponent from "./PublishStatusComponent";

interface Props {
  cbe: CanonicalBusinessEvent;
}

export default function CbeEntry(props: Props) {
  const { cbe } = props;
  const darkMode = useContext(DarkModeContext);
  return (
    <Container>
      <PublishStatusComponent cbe={cbe} />
      <ExpandableSection
        variant="default"
        header={
          <Box variant="h4">
            {cbe.idempotenceId} (V{cbe.idempotenceVersion})
            <br />
            {cbe.creationDate && (
              <TextContent>
                <small>Accounting Operation: {cbe.accountingOperation}</small>
                <br />
                <small>Destination: {cbe.destination} </small>
                <br />
                <small>
                  Created on:{" "}
                  {new Date(cbe.creationDate).toISOString().replace("T", " ")}
                </small>
                <br />
                <small>
                  PK: {cbe.pk}, SK: {cbe.sk}
                </small>
                <br />
                <small>Source Group Version: {cbe.sourceGroupVersion}</small>
              </TextContent>
            )}
          </Box>
        }
      >
        <ReactJson
          src={cbeWithoutPublishStatus(cbe)}
          displayDataTypes={false}
          collapsed={2}
          sortKeys
          theme={darkMode ? BASE_16_CUSTOM_DARK_THEME : "rjv-default"}
        />
      </ExpandableSection>
    </Container>
  );
}

// Since publish status is not an actual field from a CBE,
// we can delete it from the JSON as we've already rendered it in the Popover
function cbeWithoutPublishStatus(cbe: CanonicalBusinessEvent) {
  const cbeCopy = { ...cbe };
  delete cbeCopy.publishStatus;
  return cbeCopy;
}
