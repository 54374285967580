import { load, dumpPrettyText } from "ion-js";
import { ErrorDetails, ErrorRecord } from "../types";

const MAX_LINE_LENGTH = 80;
const INDENT_2_SPACES = "  ";
const INDENT_4_SPACES = "    ";
const BULLET_POINT = "* ";
const NEW_LINE = "\n";

export function wrapLongLine(
  line: string,
  maxLength: number,
  initialIndent: string,
  subsequentIndent: string
): string {
  const words = line.split(" ");
  let wrappedLine = initialIndent;
  let currentLineLength = initialIndent.length;

  for (const word of words) {
    if (currentLineLength + word.length + 1 > maxLength) {
      wrappedLine += `${NEW_LINE}${subsequentIndent}${word}`;
      currentLineLength = subsequentIndent.length + word.length;
    } else {
      if (currentLineLength > initialIndent.length) {
        wrappedLine += " ";
        currentLineLength += 1;
      }
      wrappedLine += word;
      currentLineLength += word.length;
    }
  }

  return wrappedLine;
}

function isNewErrorStart(line: string): boolean {
  return (
    line.startsWith("Failed to derive attribute") ||
    line.startsWith("Missing required attribute") ||
    line.startsWith("Multiple Client Errors")
  );
}

// Format errors like "Failed to derive attribute <X>: Attempted using values from"
function formatAttemptedValues(line: string): string {
  const match = line.match(/Attempted using values from (.*) in (.*)/);
  if (match) {
    const [, valuesSection, mappingSection] = match;
    return (
      `${BULLET_POINT}Attempted using values from:${NEW_LINE}` +
      `${INDENT_2_SPACES}${BULLET_POINT}${wrapLongLine(
        valuesSection,
        MAX_LINE_LENGTH,
        INDENT_4_SPACES,
        INDENT_4_SPACES
      ).trim()}${NEW_LINE}` +
      `${BULLET_POINT}in ${wrapLongLine(
        mappingSection,
        MAX_LINE_LENGTH,
        INDENT_2_SPACES,
        INDENT_2_SPACES
      ).trim()}${NEW_LINE}`
    );
  }
  return `${BULLET_POINT}${wrapLongLine(
    line,
    MAX_LINE_LENGTH,
    INDENT_2_SPACES,
    INDENT_2_SPACES
  ).trim()}${NEW_LINE}`;
}

function updateErrorBlock(currentBlock: string, line: string): string {
  if (line.startsWith("Attempted using values")) {
    return currentBlock + formatAttemptedValues(line);
  }
  if (line === "Schema Definition Processing Error]") {
    return `${currentBlock}${line.replace("]", "")}${NEW_LINE}`;
  }
  return `${currentBlock}${wrapLongLine(
    line,
    MAX_LINE_LENGTH,
    "",
    ""
  )}${NEW_LINE}`;
}

function formatErrorMessage(message: string): string {
  const lines = message.split(". ");
  let formattedErrors = "";
  let currentErrorBlock = "";
  let isFirstError = true;

  for (const line of lines) {
    const trimmedLine = line.trim();

    if (isNewErrorStart(trimmedLine)) {
      if (!isFirstError) {
        formattedErrors += `${currentErrorBlock}${NEW_LINE}${NEW_LINE}`;
      }
      currentErrorBlock = `${trimmedLine}:${NEW_LINE}`;
      isFirstError = false;
    } else {

      currentErrorBlock = updateErrorBlock(currentErrorBlock, trimmedLine);
    }
  }

  if (currentErrorBlock) {
    formattedErrors += currentErrorBlock;
  }

  return formattedErrors.trim();
}

export function parseErrorDetails(
  stackTrace: string,
  destination: string
): ErrorDetails {
  try {
    if (destination === "FLARE") {
      const ionMatch = stackTrace.match(/{[\s\S]*}/);
      if (ionMatch) {
        const ionData = load(ionMatch[0]);
        const userFriendlyMessage = ionData?.get("message")?.stringValue();
        const rawErrorDetails = dumpPrettyText(ionData?.get("errors"));
        const inputRequestUrl =
          ionData?.get("input_request_url")?.stringValue() || undefined;
        const errorType =
          ionData?.get("type")?.stringValue() || "Unknown Error";

        const displayMessage = userFriendlyMessage
          ? formatErrorMessage(userFriendlyMessage)
          : stackTrace;

        const parsedErrorDetails: ErrorRecord | undefined = ionData?.get(
          "errors"
        )
          ? (JSON.parse(JSON.stringify(ionData.get("errors"))) as ErrorRecord)
          : undefined;

        const errorRecords: ErrorRecord[] | undefined = ionData?.get(
          "errored_records"
        )
          ? (JSON.parse(
              JSON.stringify(ionData.get("errored_records"))
            ) as ErrorRecord[])
          : undefined;

        return {
          errorType,
          displayMessage,
          rawErrorDetails,
          inputRequestUrl,
          parsedErrorDetails,
          errorRecords,
          stackTrace,
        };
      }
    }

    // Fallback for rACE errors
    const errorTypeMatch = stackTrace.match(/^([\w.]+Exception):/);
    const errorType = errorTypeMatch ? errorTypeMatch[1] : "Unknown Error";

    return {
      errorType,
      displayMessage: stackTrace,
      stackTrace,
    };
  } catch (error) {
    console.error("Error parsing error details:", error);
    return {
      errorType: "Parsing Error",
      displayMessage: stackTrace,
      stackTrace,
    };
  }
}
