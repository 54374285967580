import { ColumnLayout, Header, Link } from "@amzn/awsui-components-react";
import { useContext, useEffect } from "react";
import {
  AccountingEventsGroup,
  CanonicalBusinessEvent,
  DigitalBusinessDocument,
} from "../../types";
import { OrderIdContext, StageContext } from "../../contexes/OrderIdContext";
import { useMarketplaceId } from "../../contexes/MarketplaceIdContext";
import { BETA_STAGE, PROD_STAGE } from "../../constants";
import { useDeferralId } from "../../contexes/DeferralIdContext";

interface Realms {
  NA: string;
  EU: string;
  FE: string;
}

const realms: Realms = { NA: "na", EU: "eu", FE: "fe" };

// Reference https://code.amazon.com/packages/PVAccountingCDK/blobs/mainline/--/lib/marketplaces.ts
const MARKETPLACE_TO_REALM: Record<string, string> = {
  A1AM78C64UM0Y8: realms.NA, // MEXICO_PROD
  A3P3J5A7D2ZVXI: realms.NA, // MEXICO_DEVO
  A39IBJ37TRP1C6: realms.FE, // AUSTRALIA_PROD
  A1RNPCQ4K8U27I: realms.FE, // AUSTRALIA_ DEVO
  A1C3SOZRARQ6R3: realms.EU, // POLAND_PROD
  AKY4K4WKH21YQ: realms.EU, // POLAND_DEVO
  AMEN7PMS3EDWL: realms.EU, // BELGIUM_PROD
  A1YFOMBPFBRY22: realms.EU, // BELGIUM_DEVO
  A2NODRKZP88ZB9: realms.EU, // SWEDEN_PROD
  AT614YYYNOC1S: realms.EU, // SWEDEN_DEVO
  A13V1IB3VIYZZH: realms.EU, // FRANCE
  APJ6JRA9NG5V4: realms.EU, // ITALY_PROD
  A3HOBANJMCMD83: realms.EU, // ITALY_DEVO
  A1RKKUPIHCS9HS: realms.EU, // SPAIN_PROD
  AJZF8LZ1EJVJN: realms.EU, // SPAIN_DEVO
  A2EUQ1WTGCTBG2: realms.NA, // CANADA
  A1805IZSGTT6HS: realms.EU, // NETHERLANDS_PROD
  A1M3WC0SJ3A38T: realms.EU, // NETHERLANDS_DEVO
  ART4WZ8MWBX2Y: realms.NA, //  ROW_NA_PROD
  A3U49GLEJ1PS4Y: realms.NA, // ROW_NA_DEVO
  A3K6Y4MI8GDYMT: realms.EU, // ROE_EU_PROD
  A1MJEQWJOUEPEQ: realms.EU, // ROE_EU_DEVO
  AZXD3QD5B39HD: realms.NA, // BRAZIL_DEVO
  A2Q3Y263D00KWC: realms.NA, // BRAZIL_PROD
  A2MFUE2XK8ZSSY: realms.EU, // ROW_EU_PROD
  A1D7Z662KHSYZY: realms.EU, // ROW_EU_DEVO
  A15PK738MTQHSO: realms.FE, // ROW_FE_PROD
  A9QSUUOYRWVOG: realms.FE, // ROW_FE_DEVO
  ATVPDKIKX0DER: realms.NA, // US
  A1F83G8C2ARO7P: realms.EU, // UK
  A1PA6795UKMFR9: realms.EU, // GERMANY
  A1VC38T7YXB528: realms.FE, // JAPAN
  A21TJRUUN4KGV: realms.EU, // India
};

const MARKETPLACE_TO_COUNTRY_CODE: Record<string, string> = {
  A1AM78C64UM0Y8: "MX",
  A3P3J5A7D2ZVXI: "MX_DEVO",
  A39IBJ37TRP1C6: "AU",
  A1RNPCQ4K8U27I: "AU_DEVO",
  A1C3SOZRARQ6R3: "PL",
  AKY4K4WKH21YQ: "PL_DEVO",
  AMEN7PMS3EDWL: "BE",
  A1YFOMBPFBRY22: "BE_DEVO",
  A2NODRKZP88ZB9: "SE",
  AT614YYYNOC1S: "SE_DEVO",
  A13V1IB3VIYZZH: "FR",
  APJ6JRA9NG5V4: "IT",
  A3HOBANJMCMD83: "IT_DEVO",
  A1RKKUPIHCS9HS: "ES",
  AJZF8LZ1EJVJN: "ES_DEVO",
  A2EUQ1WTGCTBG2: "CA",
  A1805IZSGTT6HS: "NL",
  A1M3WC0SJ3A38T: "NL_DEVO",
  ART4WZ8MWBX2Y: "ROW_NA_PROD",
  A3U49GLEJ1PS4Y: "ROW_NA_DEVO",
  A3K6Y4MI8GDYMT: "ROE_EU_PROD",
  A1MJEQWJOUEPEQ: "ROE_EU_DEVO",
  AZXD3QD5B39HD: "BR",
  A2Q3Y263D00KWC: "BR",
  A2MFUE2XK8ZSSY: "ROW_EU_PROD",
  A1D7Z662KHSYZY: "ROW_EU_DEVO",
  A15PK738MTQHSO: "ROW_FE_PROD",
  A9QSUUOYRWVOG: "ROW_FE_DEVO",
  ATVPDKIKX0DER: "US",
  A1F83G8C2ARO7P: "GB",
  A1PA6795UKMFR9: "DE",
  A1VC38T7YXB528: "JP",
  A21TJRUUN4KGV: "IN",
};

export const getCountryCodeFromMarketplace = (
  marketplaceId: string
): string => {
  return MARKETPLACE_TO_COUNTRY_CODE[marketplaceId] || "";
};

interface DeferralIds {
  revenueDeferralId?: string;
  royaltyDeferralId?: string;
}

export const getDeferralIds = (cbe: CanonicalBusinessEvent): DeferralIds => {
  let revenueDeferralId: string | undefined;
  let royaltyDeferralId: string | undefined;

  for (const record of Object.values(cbe.records)) {
    if (record["@type"] === "DeferralRecord" && record.content) {
      if (record.content.accountType === "digital_subscription_deferral_item") {
        revenueDeferralId = record.content.deferralId;
      } else if (record.content.accountType === "digital_royalty_pre_paid") {
        royaltyDeferralId = record.content.deferralId;
      }
    }

    // Fallback logic for older CBEs
    if (!revenueDeferralId) {
      if (record.clientAttributes && record.clientAttributes.deferral_id) {
        const deferralIdAttr = record.clientAttributes.deferral_id;
        if (
          typeof deferralIdAttr === "object" &&
          "stringValue" in deferralIdAttr &&
          typeof deferralIdAttr.stringValue === "string"
        ) {
          // For older CBEs, we'll only set the revenueDeferralId
          revenueDeferralId = deferralIdAttr.stringValue;
        }
      }
    }
  }

  return { revenueDeferralId, royaltyDeferralId };
};

export const createFlashUILink = (
  marketplaceId: string,
  orderId: string,
  selectedStage: string
) => {
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  const stage =
    selectedStage === PROD_STAGE ? "" : `-${selectedStage.toLowerCase()}`;
  const alias = selectedStage === BETA_STAGE ? "integ" : "aka";
  return `https://flashui${stage}-${realm}.${alias}.amazon.com/search/transaction?transactionId=${encodeURIComponent(
    orderId
  )}`;
};

const createDigiConLink = (marketplaceId: string, orderId: string) => {
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  if (realm !== realms.NA) {
    const realmSuffix = `${realm.toUpperCase()}Amazon`;
    return `https://digicon-${realm}.amazon.com/order-dumper?orderID=${orderId}&realm=${realmSuffix}`;
  }
  return `https://digicon.amazon.com/order-dumper?orderID=${orderId}`;
};

export const createRaceLink = (
  marketplaceId: string,
  deferralId: string,
  selectedStage: string
) => {
  const namespace = selectedStage === PROD_STAGE ? "prod" : "uat";
  if (selectedStage === BETA_STAGE) {
    return `https://race-website.integ.amazon.com:13743/deferral_search/index?deferral-id=${encodeURIComponent(
      deferralId
    )}&namespace=test`;
  }
  const realm = MARKETPLACE_TO_REALM[marketplaceId];
  return `https://race-website-${realm}.aka.amazon.com/deferral_search/index?deferral-id=${encodeURIComponent(
    deferralId
  )}&namespace=${namespace}`;
};

interface Props {
  accountingEventsGroup?: AccountingEventsGroup;
  digitalBusinessDocument?: DigitalBusinessDocument;
}

export default function ExternalLinks({
  accountingEventsGroup,
  digitalBusinessDocument,
}: Props) {
  const orderId = useContext(OrderIdContext);
  const stage = useContext(StageContext);
  const { setMarketplaceId } = useMarketplaceId();
  const { deferralIds } = useDeferralId();

  const getMarketplaceId = () => {
    if (accountingEventsGroup && accountingEventsGroup.accountingEvents) {
      const firstEvent = Object.values(
        accountingEventsGroup.accountingEvents
      )[0];
      return firstEvent?.marketplaceId || "";
    }
    return digitalBusinessDocument?.body?.header?.marketplaceId || "";
  };

  const getDigitalOrderId = () => {
    if (accountingEventsGroup && accountingEventsGroup.accountingEvents) {
      const firstEvent = Object.values(
        accountingEventsGroup.accountingEvents
      )[0];
      return firstEvent?.orderId || "";
    }
    return digitalBusinessDocument?.body?.header?.id || "";
  };

  const marketplaceId = getMarketplaceId();
  const digitalOrderId = getDigitalOrderId();

  useEffect(() => {
    if (marketplaceId) {
      setMarketplaceId(marketplaceId);
    }
  }, [marketplaceId, setMarketplaceId]);

  const links = [
    {
      key: "flash-ui",
      href: createFlashUILink(marketplaceId, orderId, stage),
      testId: "FlashLink",
      text: "Flash UI Transaction Search",
    },
    {
      key: "race-revenue",
      href: createRaceLink(
        marketplaceId,
        deferralIds.revenueDeferralId || orderId,
        stage
      ),
      testId: "RaceRevenueLink",
      text: "Race Revenue Deferrals",
    },
  ];

  if (
    deferralIds.royaltyDeferralId &&
    deferralIds.royaltyDeferralId !== deferralIds.revenueDeferralId
  ) {
    links.push({
      key: "race-royalty",
      href: createRaceLink(marketplaceId, deferralIds.royaltyDeferralId, stage),
      testId: "RaceRoyaltyLink",
      text: "Race Royalty Deferrals",
    });
  }

  if (stage !== BETA_STAGE && digitalOrderId) {
    links.push({
      key: "digicon",
      href: createDigiConLink(marketplaceId, digitalOrderId),
      testId: "DigiconLink",
      text: "DigiCon Order Dumper",
    });
  }

  return (
    <div>
      <ColumnLayout columns={2}>
        {links.map((link) => (
          <Header key={link.key}>
            <Link
              external
              externalIconAriaLabel="Opens in a new tab"
              href={link.href}
              data-testid={link.testId}
            >
              {link.text}
            </Link>
          </Header>
        ))}
      </ColumnLayout>
    </div>
  );
}
