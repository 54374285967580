import { useContext, useEffect, useState } from "react";
import {
  Box,
  ColumnLayout,
  Container,
  Select,
  SpaceBetween,
} from "@amzn/awsui-components-react";
import Button from "@amzn/awsui-components-react/polaris/button";
import Input, { InputProps } from "@amzn/awsui-components-react/polaris/input";
import { useSearchParams } from "react-router-dom";
import { OptionDefinition } from "@amzn/awsui-components-react/polaris/internal/components/option/interfaces";
import useFetchEvent from "../hooks/useFetchEvent";
import useFetchCbe from "../hooks/useFetchCbe";
import { OrderIdContext, StageContext } from "../contexes/OrderIdContext";
import { useMarketplaceId } from "../contexes/MarketplaceIdContext";
import { GAMMA_STAGE, PROD_STAGE, BETA_STAGE } from "../constants";
import { SearchHistoryInterface } from "../types";
import { getCountryCodeFromMarketplace } from "./OrderIdBar/ExternalLinks";
import { useHealthState } from "../contexes/HealthStateContext";

interface Props {
  children: React.ReactNode;
  setStage: React.Dispatch<React.SetStateAction<string>>;
}

export default function SearchBar({ children, setStage }: Props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const orderIdParam =
    searchParams.get("transactionId") || searchParams.get("orderId");
  const [orderId, setOrderId] = useState("");
  const [orderIdQuery, setOrderIdQuery] = useState(orderIdParam || "");

  const stage = useContext(StageContext) || "prod";
  const stageParam = searchParams.get("stage") || "Prod";
  const [isStageDropdownDisabled, setStageDropdownDisabledStatus] =
    useState(false);
  const [selectedOption, setSelectedOption] = useState({
    label: stageParam,
    value: stageParam,
  });

  const { refetch: refetchEvents } = useFetchEvent(stage, orderId);
  const { refetch: refetchCbes } = useFetchCbe(stage, orderId);

  const { marketplaceId } = useMarketplaceId();
  const healthState = useHealthState();

  const ORDER_IDS = "orderIds";
  const ENTER_KEY = "Enter";

  const updateSearchHistory = (
    newEntry: Partial<SearchHistoryInterface>,
    maxEntries = 300
  ) => {
    if (!orderId || !orderId.trim()) return;

    const existingEntries: SearchHistoryInterface[] = JSON.parse(
      localStorage.getItem(ORDER_IDS) || "[]"
    ) as SearchHistoryInterface[];

    const existingEntry = existingEntries.find(
      (entry) => entry.orderId === orderId && entry.stage === stage
    );

    if (existingEntry) {
      Object.assign(existingEntry, newEntry);
      existingEntry.date = new Date().toLocaleString();
    } else {
      existingEntries.unshift({
        ...newEntry,
        orderId,
        stage,
        date: new Date().toLocaleString(),
      } as SearchHistoryInterface);
    }

    const latestEntries = existingEntries.slice(0, maxEntries);

    localStorage.setItem(ORDER_IDS, JSON.stringify(latestEntries));
  };

  const saveMarketplaceId = (updatedMarketplaceId?: string) => {
    const effectiveMarketplaceId = updatedMarketplaceId || marketplaceId || "";
    const countryCode = getCountryCodeFromMarketplace(effectiveMarketplaceId);

    if (countryCode.length > 0) {
      updateSearchHistory({ countryCode });
    }
  };

  const saveHealthStatus = (status: string) => {
    if (status && status.toLowerCase() !== "unknown") {
      updateSearchHistory({ status });
    }
  };

  const handleClick = () => {
    setOrderId(orderIdQuery);
    setStage(selectedOption.value);
    if (orderIdQuery !== "")
      setSearchParams(
        { stage: selectedOption.value, transactionId: orderIdQuery },
        { replace: true }
      );
  };

  const handleSelectStageClick = (selectedStage: OptionDefinition) => {
    setSelectedOption({
      label: selectedStage.label!,
      value: selectedStage.value!,
    });
  };

  const handleOnKeyDown = (detail: InputProps.KeyDetail) => {
    if (detail.key === ENTER_KEY) {
      handleClick();
    }
  };

  useEffect(() => {
    if (orderIdParam) setOrderId(orderIdParam);
  }, [orderIdParam]);

  useEffect(() => {
    if (stageParam) setStage(stageParam);
  }, [stageParam]);

  useEffect(() => {
    if (orderId && stage) {
      (async () => {
        setStageDropdownDisabledStatus(true);
        try {
          await refetchEvents();
          await refetchCbes();
          updateSearchHistory({ orderId, stage });
        } catch (error) {
          console.error(error);
        } finally {
          setStageDropdownDisabledStatus(false);
        }
      })();
    }
  }, [orderId, stage]);

  useEffect(() => {
    if (orderId && stage) {
      if (marketplaceId) {
        saveMarketplaceId(marketplaceId);
      }

      if (
        healthState.overallHealth &&
        healthState.overallHealth.toLowerCase() !== "unknown"
      ) {
        saveHealthStatus(healthState.overallHealth);
      }
    }
  }, [marketplaceId, healthState.overallHealth, orderId, stage]);

  useEffect(() => {
    if (orderId) {
      document.title = `${orderId} | Lusca Search`;
    }
  }, [orderId]);

  window.onpopstate = () => {
    setOrderId("");
    setOrderIdQuery("");
  };

  return (
    <OrderIdContext.Provider value={orderId}>
      <SpaceBetween size="xl">
        <Container>
          <ColumnLayout columns={1}>
            <Box padding={{ top: "s", bottom: "s" }}>
              <div className="custom-search-bar">
                <div className="custom-search-bar-input">
                  <Input
                    onChange={({ detail }) => setOrderIdQuery(detail.value)}
                    value={orderIdQuery}
                    onKeyDown={({ detail }) => handleOnKeyDown(detail)}
                    type="search"
                    placeholder="Enter Transaction Id (TVOD: Order ID, Subs: AUID)"
                    autoFocus
                  />
                </div>
                <Select
                  className="custom-select"
                  selectedOption={selectedOption}
                  placeholder="Choose an environment"
                  onChange={({ detail }) =>
                    handleSelectStageClick(detail.selectedOption)
                  }
                  options={[
                    { label: PROD_STAGE, value: PROD_STAGE },
                    { label: GAMMA_STAGE, value: GAMMA_STAGE },
                    { label: BETA_STAGE, value: BETA_STAGE },
                  ]}
                  disabled={isStageDropdownDisabled}
                  selectedAriaLabel="Selected"
                />
                <div className="custom-search-bar-items">
                  <Button
                    onClick={handleClick}
                    variant="primary"
                    className="inp"
                  >
                    Search
                  </Button>
                </div>
              </div>
            </Box>
          </ColumnLayout>
        </Container>
        {children}
      </SpaceBetween>
    </OrderIdContext.Provider>
  );
}
