import React, { createContext, useContext, useState, ReactNode, useMemo } from "react";

export interface DeferralIds {
  revenueDeferralId: string;
  royaltyDeferralId: string;
}

interface DeferralIdContextProps {
  deferralIds: DeferralIds;
  setDeferralIds: React.Dispatch<React.SetStateAction<DeferralIds>>;
}

const DeferralIdContext = createContext<DeferralIdContextProps | undefined>(undefined);

export function DeferralIdProvider({ children }: { children: ReactNode }) {
  const [deferralIds, setDeferralIds] = useState<DeferralIds>({
    revenueDeferralId: "",
    royaltyDeferralId: "",
  });

  const value = useMemo(() => ({ deferralIds, setDeferralIds }), [deferralIds]);

  return (
    <DeferralIdContext.Provider value={value}>
      {children}
    </DeferralIdContext.Provider>
  );
}

export const useDeferralId = () => {
  const context = useContext(DeferralIdContext);
  if (!context) {
    throw new Error("useDeferralId must be used within DeferralIdProvider");
  }
  return context;
};
