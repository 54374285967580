import React from "react";
import {
  Box,
  Popover,
  StatusIndicator,
  BoxProps,
} from "@amzn/awsui-components-react";
import { HealthStatus } from "../types";
import { StatusIndicatorProps } from "@amzn/awsui-components-react/polaris/status-indicator/internal";

interface HealthIndicatorEntry {
  type: StatusIndicatorProps.Type;
  color: BoxProps.Color;
  message: string;
}

const healthIndicator: Record<HealthStatus, HealthIndicatorEntry> = {
  healthy: {
    type: "success",
    color: "text-status-success",
    message: "This transaction is healthy",
  },
  unhealthy: {
    type: "error",
    color: "text-status-error",
    message: "This transaction has issues",
  },
  pending: {
    type: "pending",
    color: "text-status-inactive",
    message: "This transaction is in progress",
  },
  unknown: {
    type: "in-progress",
    color: "text-status-inactive",
    message: "This transaction is unknown",
  },
};

interface HealthStatusIndicatorProps {
  healthStatus: HealthStatus;
  popoverContent?: React.ReactNode;
  minimal?: boolean;
}

const HealthStatusIndicator: React.FC<HealthStatusIndicatorProps> = ({
  healthStatus,
  popoverContent,
  minimal = false,
}) => {
  const { type, color, message } = healthIndicator[healthStatus] || healthIndicator.unknown;

  const statusIndicatorContent = (
    <StatusIndicator type={type}>
      {minimal ? null : healthStatus.charAt(0).toUpperCase() + healthStatus.slice(1)}
    </StatusIndicator>
  );

  return (
    <Box color={color}>
      {minimal || !popoverContent ? (
        statusIndicatorContent
      ) : (
        <Popover header={message} content={popoverContent}>
          {statusIndicatorContent}
        </Popover>
      )}
    </Box>
  );
};


export default HealthStatusIndicator;
