import { useQuery } from "@tanstack/react-query";
import { EventsApiFactory } from "../lusca-search-api/LuscaSearchApiFactory";
import { AccountingEventsGroup, DigitalBusinessDocument } from "../types";
import removeEmpty from "../helpers/removeEmpty";
import { useHealthStateUpdate } from "../contexes/HealthStateContext";

export default function useFetchEvent(stage: string, postId: string) {
  const EventsApi = EventsApiFactory();
  const setState = useHealthStateUpdate();

  const getEvent = async () => {
    const { data } = await EventsApi.getEvents(stage, postId);
    return data.map((element) => {
      setState((prev) => ({
        ...prev,
        eventsReceived: data.length > 0,
      }));

      // Check if documentType exists in the cleaned element
      if ("documentType" in element) {
        return removeEmpty(element) as DigitalBusinessDocument;
      }
      return removeEmpty(element) as AccountingEventsGroup;
    });
  };

  return useQuery(["post event", postId], () => getEvent(), {
    refetchOnWindowFocus: false,
    retry: false, // Disable retries
    enabled: false, // disable this query from automatically running
  });
}
